export default {
  route: {
    'home': 'SIM',
    'mine': 'User Center',
    'topup':'Top-up',
    'search': 'Search',
    'my_cards': 'My Card(s)',
    'search_tips': 'Search',
    'login': 'Login',
    'logout': 'Logout',
    'sim_order':'Sim card orders',
    'flow_order':'Top-up orders',
    'address':'Address',
    'guide':'User Manual',
    'user':'Account',
  },
  common:{
    'qantas_title':'Exclusive Gift',
    'qantas_official':'Air Macau Official',
    'qantas_get':'Get it Now',
    'coupon':'Coupon',
    'card_fee':'SIM card fee included',
    'each':'Each',
    'cancel':'Cancel',
    'ok':'Confirm',
    'all':'All Data Plan',
    'hot':'Popular Area',
    'area':'Coverage',
    'area_global':'Global with TripRoaming',
    'other':'Other Data Plan',
    'slogan':'TravelMate the signal is on your side',
    'price':'Price',
    'from':'From ',
    'tup-op_tips':'Enter sim card number',
    'title_1':'Specification：',
    'title_2':'Days：',
    'title_3':'Instruction',
    'title_4':'Quantity',
    'title_5':'ICCID',
    'title_6':'Please select collection method',
    'title_7':'Please choose delivery address',
    'title_8':'Please add the shipping address',
    'title_9':'Please select a postal service',
    'title_10':'Please select the SIM Card package you want to recharge',
    'btn_1':'Buy now',
    'btn_2':'Top-up',
    'btn_3':'Add new address',
    'pay_1':'Confirm',
    'pay_2':'Order information',
    'pay_3':'Latest use time：',
    'pay_4':'Select payment method',
    'pay_5':'Amount due',
    'pay_6':'Confirm pay',
    'pay_7':'Order amount：',
    'pay_8':'Order No：',
    'pay_9':'Submitted successfully, please pay ASAP',
    'pay_10':'Cancel',
    'pay_11':'Complete',
    'pay_12':'Checking, please wait',
    'pay_13':'Total：',
    'pay_14':'Mailing',
    'pay_15':'pickup',
    'search_1':'Search key word：',
    'search_2':'Search result',
    'search_3':'No result',
    'search_4':'Please enter key word',
    'check_order':'Check order',
    'pay_again':'Pay Again',
    'back_home':'Back Home',
    'result_success':'Payment Successful',
    'result_fail':'Payment Failure',
    'result_tips':'Order number: {text} Order configuration takes 1-3 minutes, please wait.',
  },
  mycards:{
    'tag_1':'Reusable',
    'tag_2':'Plug and go',
    'tag_3':'Signal Satabilization',
    'bind_title':'TravelMate Global SIM Card',
    'bind_desc':'Query data usage Easy to Top-up',
    'bind_tips':'Enter card number',
    'bind_btn':'Add',
    'add_title':'Add sim card',
    'tab_1': 'My Card(s)',
    'tab_2': 'Expired Data',
    'unbind':'Unbind',
    'topup':'Top-up',
    'handle_again':'Buy Again',
    'handle_stop':'Termination',
    'handle_use':'Priority use',
    'last_time':'Recently data',
    'empty':'This is data plan on this card',
    'expired':'Expired data',
    'data_use':'DATA USED',
    'use_tips':'A certain delay to data usage display. <br/>Please refer to the actual usage.',
    'unbind_text':'Once unbound',
    'unbind_tips':'the cards will not show in the list. Enter the card number to rebind for details.',
    'stop':'Termination',
    'stop_tips_1':`Once 'Termination'`,
    'stop_tips_2':'will be terminated and can NOT be used again.',
    'use':'Priority use',
    'use_tips_1':`Once 'Priority Use'`,
    'use_tips_2':', ',
    'use_tips_3':'will be terminated and can NOT be used again.',
    'use_tips_4':`Upon arrival at your destination (with 'Data Roaming'turning on), `,
    'use_tips_5':'will be automatically activated.',
    'cancel':'Back',
    'confirm':'Confirm',
    'iccid':'ICCID：',
    'destination':'Destination：',
    'topup_title':'Select specifications',
    'topup_subtitle':'Please select the data plan specifications you want to top up',
    'topup_confirm':'Top-up Confirmation',
    'next':'Next',
    'fold':'Fold',
    'unfold':'View more data plan',
  },
  reviews:{
    'title':'TravelMate SIMs are relied on by over 1,000,000+ users worldwide',
    'add_up':'From 5,000+',
    'reviews':'Reviews'
  },
  footer:{
    'name':'TripRoaming Tech(ShenZhen) Co.,Ltd.',
    'hk_name':'Hong Kong Headquarters',
    'hk_addess':'Unit 1103, 11/F., Orient International Tower, 1018 Tai Nan West Street, Cheung Sha Wan, Kowloon, Hong Kong',
    'am_name':'Macau Branch office',
    'am_addess':"F & G, 17FL, Centro Commercial Cheng Feng, 336-342 Alameda Dr. Carlos D'Assumpcao",
    'sz_name':'Shenzhen Branch office',
    'sz_addess':'Unit 2113, Oriental Technology Building, 3 Kewei Road, NanShan, Shenzhen',
    'tw_name':'Taiwan Branch office',
    'tw_addess':'No. 35, Yongding 2nd St., Beitun Dist., Taichung City 406026, Taiwan (R.O.C.)',
    'link_title':'Link',
    'link_yyb':'Triproaming Official Website',
    'link_wyb':'TravelMate Data Mall',
    'link_esim':'TraveleSIM Data Mall',
    'contact_us':'Contact us',
    'commerce_contact':'Commerce Contact',
    'scan_app':'Scan to download APP',
    'scan_wechat':'Scan to follow the ',
    'download':'Download TravelMate APP',
    'rights':'All Rights Reserved.'
  },
  invite:{
    'mycode':'Your Exclusive Invitation Code',
    'code':'Your Exclusive Invitation Code',
    'copy':'Copy',
    'copy_success':'Copy already',
    'copy_fail':'Copy failed',
    'share':'Share now',
    'records':'Invitation Record',
    'use':'Go to use the coupon',
    'no_record':'No record yet, to share your exclusive invitation code now~',
    'know':'I know it',
    'now':'Experience now',
    'invited':'The total quantity of friends invited',
    'coupons':'A total of coupons (pcs)',
    'rules':'Activity Rules',
    'text_1':'Share your invitation code to get the coupons.',
    'text_2':'Share Invitation Code',
    'text_3':'Recommend TravelMate to friends by sharing the exclusive invitation code.',
    'text_4':'New Register Coupon',
    'text_5':'Remind your friends to register with your invitation code and get the gift.',
    'text_6':'Get Your Reward',
    'text_7':'You will gain a ￥5 coupon reward once the invitee finished the registration.',
    'text_8':'Share text has been copied',
    'text_9':'The text has been copied, please share it Give it to your friends, TravelMate the signal is on your side~',
    'tips':'Claim your gifts on our "Data Mall" by searching "TravelMate" in the WeChat Official Account.'

  },
  http: {
    'timeout':'Server response time out, try again!',
    'fail': 'Failed to get data',
    'err': 'Request interface failed',
    'server_fail':'Failed to connect to server',
    '400':'Request Error',
    '404':'The requested resource was not found.',
    '500':'Something went wrong at server end',
    '502':'Network error',
    '504':'Connection timeout',
  },
  login: {
    'reset':'Inconsistent password',
    'logout_title':'Logout',
    'logout_text':'Confirm to logout?',
    'logout_ok':'Logout',
    'title_1':'Login',
    'title_2':'Register',
    'title_3':'Forgot password',
    'tips_1':'Enter phone number/E-mail',
    'tips_2':'Confirm password',
    'tips_3':'Enter password',
    'tips_4':'Enter code',
    'tips_5':'Get code',
    'tips_6':'Account existed,',
    'tips_7':'Login',
    'tips_8':'Enter new password',
    'tips_9':'Confirm new password',
    'tips_10':'Go back',
    'area':'Select area',
    'treaty_1':'《User Agreement》',
    'treaty_2':'《Privacy Policy》',
    'text_1':`Enter Phone/Email, creat TravelMate account. Click"login", that's mean you agree our`,
    'text_2':'Please verify your email',
    'text_3':'Please verify your phone',
    'text_4':`Enter Phone/Email, creat TravelMate account. Click"Sign up", that's mean you agree our`,
    'text':'and',
    'tab_1':'or phone',
    'tab_2':'or E-mail',
  },
  mine:{
    'flow_3':'Copy already',
    'flow_4':'Copy failed',
    'empty':'No Data',
    'tips':'*The order including one Travelmate card and data plan, to know more, please download',
    'app':'Travelmate APP',
    'tab_1':'all',
    'tab_2':'Non-payment',
    'tab_3':'Non-shipped',
    'tab_4':'Non-received',
    'tab_5':'Non-pickup',
    'tab_6':'Completed',
    'tab_7':'Paid',
    'tab_8':'Closed',
    'time':'Left payment time：',
    'user_1':'Account：',
    'user_2':'Password：',
    'user_3':'Set',
    'user_4':'Modify',
    'user_5':'Binding email',
    'user_6':'Bound',
    'user_7':'Unbound',
    'user_8':'Modify',
    'user_9':'Bind',
    'user_10':'Bingding Phone',
    'user_11':'Enter old password',
    'user_12':'Enter new password',
    'user_13':'Enter E-mail',
    'user_14':'Enter phone number',
    'order_1':'Region',
    'order_2':'Booking time',
    'order_3':'Actual payment',
    'order_4':'Order status',
    'order_5':'Payment method',
    'order_6':'Action',
    'order_7':'Order No：',
    'order_8':'Buy now',
    'order_9':'Actual pay：',
    'order_10':'No booking',
    'order_11':'Shipping method',
    'order_12':'Details',
    'order_13':'Confirm receipt',
    'order_14':'Express Number：',
    'order_15':'Express Company：',
    'order_16':'Delivery status：',
    'order_17':'Logistics details',
    'order_18':'Copy',
    'copy_1':'Copy already',
    'copy_2':'Copy failed',
    'flow_1':'Latest use time：{msg}',
    'flow_2':'Cancel on {msg}',
    'add_1':'Enter name',
    'add_2':'Area',
    'add_3':'Select Region',
    'add_4':'Set as default address',
    'add_5':'Edit address',
    'add_6':'Please enter the full address',
    'add_7':'Postcode',
    'add_8':'Please enter Postcode',
    'add_9':'Edit address',
    'add_10':'Please select',
    'add_11':'Address',
    'add_12':'Default Address',
    'add_13':'Name：',
    'add_14':'Tel：',
    'add_15':'Postcode：',
    'add_16':'Delete',
    'add_17':'Edit',
    'add_18':'Default',
    'add_19':'Edit',
    'add_tips':"Can't include special character"
  },
  user:{
    'privacy_title':'Travelmate Privacy Policy',
    'privacy':`
      <p>Welcome to enjoy the services provided by TripRoaming Tech Co .Ltd(Hereafter referred to as "Triproaming" or "we"),The company registered address is A201, Qianwan Road 1, Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen</p>
      <p>Travelmate complies with applicable data protection and privacy laws and regulations ("Applicable Laws"). To the maximum extent permitted by applicable laws and subject to the valid consent of the user under the requirements of applicable laws, when the user uses Travelmate software, services, websites or any other third-party platforms that provide Travelmate services, it means that users Agree and accept this privacy policy. We may update this privacy policy from time to time to reflect changes in our software or products and user opinions, or changes in applicable laws. If there are major changes in this privacy policy or the way we use the personal data of users, we will publish relevant notices in an obvious way or send notices directly to users before implement. We encourage users to read this privacy policy regularly to learn how we are collecting, using and protecting user information.</p>
			<p class="subtitle">1. Personal information collection</p>
			<p>We may collect personal data from time to time in order to provide users with our products and services. The information includes but not limited to:</p>
			<p>(a) The information provided by the user during registration, displayed on the Travelmae APP/ online search directory/ personal center page . This may include the user's avatar, mobile phone number, etc., as well as all other information that the user agrees to provide;</p>
			<p>(b) Identification data (such as name, address, phone number, mobile phone number);</p>
			<p>(c) Electronic identification data (such as IP address, cookies); </p>
      <p>(d) Camera permission: When you using sweep function to add a SIM card, you will be asked for camera permission in a pop-up window. The camera is a sensitive permission, refusing to provide will only make you unable to use the sweep Function(you can still choose to manually input to add SIM Card), will not affect the use of other functions.</p>
      <p>(e) Application list information: In order to achieve the [Third-party social platform login] function, Triproaming uses MobTech's [ShareSDK third-party login] product. When detecting and implementing ID synchronization and merging link services for you, application list information will be obtained.</p>
			<p>(f) Information about users' use of our software, services, products or webpage interactions (including computer, client information, error reports, page browsing statistics, browser type and usage statistics);</p>
			<p>(g) Flow data (data processed for transmission of communication or billing);</p>
			<p>(h) Payment details, including recipient information and payment method;</p>
			<p>(i) Contact details, including contact name, phone number, etc.</p>
			<p>In addition, the user's online time period data may be collected indirectly in a statistical and anonymous manner.</p>
			<p>If the user is under the age of 18, the user needs to provide personal information with the consent and supervision of the parent or guardian.</p>
			<p>This privacy policy does not apply to data collected through the following methods: access to third-party services (including any third-party websites) through Travelmate services; or through information collected by other companies and organizations that promote services on Travelmate .</p>
			<p class="subtitle">2. Use of personal information</p>
			<p>Our main purpose of collecting information is to provide users with a safe, smooth and efficient experience. Subject to the effective consent of the user, we will collect and use or entrust a third-party service provider to collect and use the personal data on our behalf when we obtain permission or necessary, such as:</p>
			<p>(a) Obtain, process or transmit orders generated through our website, issue invoices to users or notify users of order status;</p>
			<p>(b) Provide users with other services (as described when we collect information);</p>
			<p>(c) Provide customers with emergency rescue and other troubleshooting services ;</p>
			<p>(d) Comparing the accuracy of information;</p>
			<p>(e) Notify users about service updates and malfunctions;</p>
			<p>(f) Resolve disputes;</p>
			<p>(g) Verify the user’s identity;</p>
			<p>(h) Improve our software, services or website;</p>
			<p>(i) Plan and execute our marketing or promotion activities, discounts and similar activities;</p>
			<p>(j) Provide certain software, functions and websites for free through advertising support;</p>
			<p>(k) Contact users and send relevant messages for faults and service issues;</p>
			<p>(l) Notification by e-mail, software or text message to let users know about the final compensation information about using Travelmate software, products or websites, including but not limited to infringement claims for infringement of the rights of third parties;</p>
			<p>(m) Use SMS or Travelmate messages to notify users of the latest information about Travelmate software, services or websites, and inform users related promotions.</p>
			<p class="subtitle">3. Disclosure and transfer of users' personal information</p>
			<p>Except as described below, without the valid and express permission of the user, Travelmate will not sell, rent, trade or transfer any personal traffic data or communication content, unless Travelmate under the obligation to do the above behaviors by applicable laws or jurisdictions.</p>
			<p>Travelmate may disclose personal information in response to legal requirements, exercise our legal rights or defend against claims, to protect Travelmate’s interests, combat fraud and implement our policies or protect users’ legal rights, property or safety .</p>
			<p>Travelmate, and its partners, or communication operators or companies that promote users may provide personal information, communication content or traffic data to government agencies that require such information in the appropriate judicial, law enforcement, or lawful manner. Subject to the valid and explicit consent of the user, Travelmate may provide the user's personal information to the supplier or Travelmate's affiliated company.</p>
			<p class="subtitle">4. User's personal information security and storage"</p>
			<p>We will store user's personal information during the period when the user is our customer or after user no longer uses our service, but this is only necessary or in response to legal requirements. We will strictly implement applicable obligations and exceptions under relevant laws, and take appropriate organizational and technical measures to protect the personal data and traffic data provided to or collected by them. The user’s personal data and traffic data are only accessible by authorized employees or third parties, and will only be saved when necessary until the original purpose of the collection or directly related purposes is fulfilled. The personal data and traffic data are saved to comply with Except for any applicable legal or contractual obligations.</p>
			<p class="subtitle">5. View or correct data</p>
			<p>The user has the right to consult the user's personal information and correct it under relevant circumstances.</p>
			<p>Unless the law requires retention or for legitimate business purposes, we will make reasonable efforts to satisfy the request to delete personal information.</p>
			<p>In accordance with current laws, we reserve the right to charge a reasonable fee for processing any data access request.</p>
			<p class="subtitle">6. Mobile phone notification service</p>
			<p>When the user's mobile phone uses Travelmate, we may use the mobile phone's notification service to let the user know the information while the user doesn’t executing or using the Travelmate application. These services may be provided by third parties. For example, messages sent to Apple devices will use Apple's emergency notification service.</p>
			<p>The notification service may receive information about the caller, the sender, and the content to provide the service, and may use this information in accordance with the terms and conditions of the service provider and the privacy policy. (Apple is a registered trademark of Apple Inc. in the United States and other countries.)</p>
			<p class="subtitle">7. Third party services</p>
			<p>7.1 In order to realize the function of third party social platform login, we use MobTech's sharesdk third party login product</p>
			<p>This product's privacy policy link: <a href="http://www.mob.com/about/policy">http://www.mob.com/about/policy</a></p>
			<p>7.2 Our products are integrated with UFIDA + SDK. UFIDA + SDK needs to collect your Mac address, unique device ID (IMEI / Android ID / IDFA / openudid / guid, SIM card IMSI information) to provide statistical analysis services, and calibrate the accuracy of report data through geographical location to provide basic anti cheating ability.</p>
			<p>This product's privacy policy link: <a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a></p>
			<p class="subtitle">8. Contact Us</p>
			<p>If users have any inquiries about this privacy policy, please contact our customer service in the Travelmate app.</p>`,
    'treaty_title':'User Agreement',
    'treaty':`
      <p>Travelmate reminds you (user) to carefully read this "User Agreement" (hereinafter referred to as the "Agreement") before registering as a user to ensure that you fully understand the terms of this agreement. Unless you accept all the terms of this agreement, or you have no right to register, log in or use the services covered by this agreement. Your registration, login, use, etc. will be regarded as acceptance of this agreement, and you agree to be bound by the terms of this agreement.</p>
      <p>This agreement stipulates the rights and obligations between Travelmate and users regarding Travelmate software services (hereinafter referred to as "services"). "Users" refer to individuals who register, log in, and use this service.</p>
			<p class="subtitle">1. Account registration</p>
			<p>1.1 The user needs to register a Travelmate account with a mobile phone number or email address before using the service. Travelmate may change the method of account registration and binding according to user or product needs without prior notice to users.</p>
			<p>1.2 Travelmate is a mobile Internet product based on geographic location, when registering, users should authorize Travelmate to disclose and use their location. Once registration done, it means the user has agreed to do that</p>
			<p>1.3 In view of the binding registration method of Travelmate account, Travelmate will automatically extract information such as the user's  mobile phone number and mobile device identification code when user registering.</p>
			<p>1.4 Travelmate needs to collect personal information that can identify the user When registering and using the service for which Travelmate can contact users if necessary, also to provide a better experience to user.</p>
			<p class="subtitle">2. Service content </p>
			<p>The specific content of this service is provided by Travelmate according to the actual situation. Travelmate may change the services and update them in new version.</p>
			<p class="subtitle">3. User's personal privacy information protection</p>
			<p>3.1 In the process of registering an account or using this service, the user may need to fill in or submit some necessary information, such as the identity information required by laws and regulations, regulatory documents (hereinafter referred to as "laws and regulations"). If the information submitted by the user is incomplete or does not meet the requirements of laws and regulations, the user may not be able to use the service or be restricted in the process of using the service</p>
			<p>3.2 Personal privacy information refers to information related to the user's personal identity or personal privacy, such as the user's real name, ID number, mobile phone number, mobile device identification code, and IP address. Non-personal private information refers to the basic record information of the user's operating status and usage habits of this service, which is clearly and objectively reflected on the Travelmate server side, other general information outside the scope of personal private information, and the above-mentioned private information that the user agrees to disclose.</p>
			<p>3.3 Travelmate respects and ensures the safety of users' personal privacy information, will prevents users' personal privacy information from being leaked, damaged or lost. When the aforementioned situation occurs or Travelmate discovers that the aforementioned situation may occur, remedial measures will be taken in a timely manner.</p>
			<p>3.4 Travelmate will not disclose user's personal privacy information to any third party without the user's permission, except following   circumstances:</p>
			<p>(1) Travelmate provides users' personal privacy information in accordance with laws and regulations or instructions from competent authorities;</p>
			<p>(2) The leakage of any personal information caused by the user sharing the registered account with others, or the leakage of personal privacy information caused by other reasons other than Travelmate;</p>
			<p>(3) Users disclose their personal privacy information to third parties by themselves;</p>
			<p>(4) The user and Travelmate and its partner units have reached an agreement on the use and disclosure of the user's personal private information;</p>
			<p>(5) In order to prevent fraudulent use of the identity of others to register, this software will verify the mobile phone number through SMS, and the SMS fee for registration or invitation will be charged by the operator;</p>
			<p>(6) Any leakage of personal privacy information of users due to hacker attacks, computer virus intrusion and other force majeure events.</p>
			<p>3.5 The user agrees that Travelmate may use the user's personal information in the following situations:</p>
			<p>(1) Travelmate sends important notices to users in a timely manner, such as software updates and changes to the terms of this agreement;</p>
			<p>(2) Travelmate conducts internal audits, data analysis and research, etc. to improve Travelmate's products, services and communication between users;</p>
			<p>(3) In accordance with this agreement, Travelmate manages, reviews user information and performs processing measures;</p>
			<p>(4) Other matters stipulated by applicable laws and regulations.</p>
			<p>Except for the above situations, Travelmate will not apply the user's personal privacy information to any other purpose without user’s permission.</p>
			<p>3.6 Travelmate attaches great importance to the protection of personal privacy information of minors. Travelmate will rely on the personal information provided by the user to determine whether the user is a minor. Any minors under the age of 18 who register for an account or use this service should obtain the permission of their parents or their legal guardians (hereinafter referred to as "guardians") in advance. Except for disclosure in accordance with laws and regulations and instructions from competent authorities, Travelmate will not use or disclose personal privacy information of minors to any third party.</p>
			<p>3.7 User confirmation: Geographical location is non-personal private information, and successful registration account by the user is deemed to be a confirmation that Travelmate is authorized to freely extract, disclose and use the user's geographic information. The user's geographic location information will be one of the user's public information, which will be disclosed by Travelmate to other users.</p>
			<p>3.8 In order to improve Travelmate's technology and services and provide users a better service experience, Travelmate will collect and use or provide users' non-personal privacy information to third parties.</p>
			<p class="subtitle">4. content specification</p>
			<p>4.1 The content mentioned in this article refers to any content created, uploaded, copied, published, and disseminated during the user's use of this service, including but not limited to account avatar, name and other registration information and authentication information, as well as other user accounts or this service The content produced.</p>
			<p>4.2 Users shall not use Travelmate account or this service to produce, upload, copy, publish, or disseminate the following contents prohibited by laws, regulations and policies:</p>
			<p>(1) Opposing the basic principles established by the Constitution;</p>
			<p>(2) Endangering national security, leaking state secrets, subverting state power, and undermining national unity;</p>
			<p>(3) Damage to national honor and interests;</p>
			<p>(4) Inciting ethnic hatred, ethnic discrimination, or undermining ethnic unity;</p>
			<p>(5) Undermining the state's religious policies and promoting cults and feudal superstitions;</p>
			<p>(6) Spreading rumors, disturbing social order, and undermining social stability;</p>
			<p>(7) Spreading obscenity, pornography, gambling, violence, murder, terror or instigating crime;</p>
			<p>(8) Insulting or slandering others, infringing on the lawful rights and interests of others;</p>
			<p>(9) Information containing other content prohibited by laws and administrative regulations.</p>
			<p>4.3 Users are not allowed to use Travelmate account or this service to make, upload, copy, publish, and disseminate the following content that interferes with the normal operation of Travelmate and infringes the legitimate rights and interests of other users or third parties:</p>
			<p>(1) Contains any sexual or sexual suggestion;</p>
			<p>(2) Contains abusive, intimidating, or threatening content;</p>
			<p>(3) Containing harassment, spam, malicious information, or deceptive information;</p>
			<p>(4) Involving other people's privacy, personal information or data;</p>
			<p>(5) Infringement of legal rights such as reputation rights, portrait rights, intellectual property rights, and trade secrets of others;</p>
			<p>(6) Contains other information that interferes with the normal operation of this service and infringes the legal rights of other users or third parties.</p>
			<p class="subtitle">5. Rules of Use</p>
			<p>5.1 Any content transmitted or published by users in or through this service does not reflect or represent, nor should it be deemed to reflect or represent Travelmate’s views, positions or policies, and Travelmate shall not bear any responsibility for this.</p>
			<p>5.2 Users are not allowed to use Travelmate account or this service to do the following:</p>
			<p>(1) Submitting or publishing false information, or embezzling other people's avatars or materials, impersonating or using the names of others;</p>
			<p>(2) Forcing or inducing other users to follow, click on linked pages or share information;</p>
			<p>(3) Fictional facts, concealing the truth in order to mislead or deceive others;</p>
			<p>(4) Using technical means to establish false accounts in batches;</p>
			<p>(5) Use Travelmate account or this service to engage in any illegal or criminal activity;</p>
			<p>(6) Making and publishing methods and tools related to the above behaviors, or operating or disseminating such methods and tools, regardless of whether these behaviors have commercial purposes;</p>
			<p>(7) Other acts that violate laws and regulations, infringe on the lawful rights and interests of other users, interfere with the normal operation of Travelmate, or without express authorization by Travelmate.</p>
			<p>5.3 The user shall be responsible for the authenticity, legality, harmlessness, accuracy and effectiveness of the information transmitted by the Travelmate account or this service. Any legal responsibility related to the information disseminated by the user shall be borne by the user, has nothing to do with Travelmate. If any damage caused to Travelmate or a third party, the user shall compensate according to law.</p>
			<p>5.4 The services may include advertisements provided by Travelmate or third-party suppliers during users’ use. Except as clearly provided by laws and regulations, users shall be responsible for the transactions conducted on the advertisement information by themselves, and Travelmate shall not be liable for any loss or damage suffered by the users due to the transactions conducted on the advertisement information or the content provided by the aforementioned advertisers.</p>
			<p class="subtitle">6. account management</p>
			<p>6.1 The ownership of the Travelmate account belongs to Travelmate. After completes the application for registration, the user obtains the right to use the Travelmate account. The right to use only belongs to the initial application registrant, and it is prohibited to give, borrow, rent, transfer or sell . Travelmate has the right to recall the user's account due to business needs.</p>
			<p>6.2 The user can change or delete the personal information, registration information and transmission content on the Travelmate account, but it should be noted that deleting the relevant information will also delete the text and pictures stored in the system by the user. The user shall bear the risk.</p>
			<p>6.3 The user is responsible for the safety of the registered account information. The user's improper storage may lead to account theft or password theft, and the user shall bear the responsibility. Users need to bear legal responsibility for the behavior of registering an account. The user agrees not to use another user's account under any circumstances. When the user suspects that another person is using his account, the user agrees to notify Travelmate immediately.</p>
			<p>6.4 The user shall abide by the terms of this agreement and use the service correctly and appropriately. If the user violates any clause in this agreement, Travelmate has the right to suspend or terminate the provision of the user's Travelmate account in accordance with the agreement. At the same time, Travelmate reserves the right to withdraw the account at any time.</p>
			<p>6.5 If the user does not log in for one year after registering the account, Travelmate can withdraw the account to avoid waste of resources, and the adverse consequences shall be borne by the user.</p>
			<p class="subtitle">7. data storage</p>
			<p>7.1 Travelmate is not fictitious and does not upload content on its own. All data is uploaded by the user. Travelmate only assumes the role of depositor.</p>
			<p>7.2 Travelmate is not responsible for the user's failure to delete or store related data in this service.</p>
			<p>7.3 Travelmate can determine the maximum storage period of the user's data in this service according to the actual situation, and allocate the maximum storage space for the data on the server. Users can back up the relevant data in this service according to their own needs.</p>
			<p>7.4 If the user stops using the service or the service is terminated, Travelmate can permanently delete the user's data from the server. After the service is stopped or terminated, Travelmate has no obligation to return any data to the user.</p>
			<p class="subtitle">8. User attention</p>
			<p>8.1. If the user violates the provisions of this agreement or related terms of service; any claims, demands or losses claimed by any third party, including reasonable attorney fees, caused or generated by the user, the user agrees to compensate the company, cooperative companies, and affiliated companies.</p>
			<p>8.2. This software involves WiFi networking and Internet services, which may be affected by unstable factors in various links. Therefore, the service has the risk of service interruption or failure to meet user requirements due to the above-mentioned force majeure, virus or hacker attacks, system instability, user location, user shutdown, and any other technology, GSM network, Internet network, communication line, etc., causing service interruption or failure to meet user requirements. The user who subscribes to the service shall bear the above risks. The service party does not guarantee the timeliness, safety, and accuracy of the service, and shall not bear any responsibility for the user's communication failure caused by this.</p>
			<p>8.3 The service party does not assume any responsibility for the loss of the service due to the server crash, network interruption, and service suspension during software upgrades caused by force majeure.</p>
			<p>8.4 Travelmate provides automatic connection in case of network disconnection. If the user registers for use, it shall be deemed to have agreed to accept this service, and the data traffic charges incurred shall be borne by the user.</p>
			<p>8.5 Users should provide and share their own WiFi hotspot information, or ensure that other shared hotspots (including but not limited to passwords and geographic locations, etc.) are within the permission of the hotspot provider, and ensure the security of all shared WiFi hotspot information. Travelmate does not assume any responsibility for problems caused by violation of this article.</p>
			<p>8.6 The user should ensure that the hot information obtained by Travelmate is used for proper purposes, and any illegal or violation of relevant guidelines is strictly prohibited. The resulting problem has nothing to do with Travelmate.</p>
			<p>8.7 The hotspot provider has the right to request that the hotspot information provided by it be removed from Travelmate's database. Travelmate will deal with it accordingly in accordance with the law.</p>
			<p class="subtitle">9. Legal and dispute resolution</p>
			<p>9.1 This agreement is governed by the laws of the People's Republic of China.</p>
			<p>9.2 Any dispute arising from or related to this agreement shall be settled through friendly negotiation between the parties; if the negotiation fails, either party may submit the relevant dispute to the Shenzhen Arbitration Commission and arbitrate in accordance with its then effective arbitration rules; arbitration awards It is final and binding on all parties.</p>
			<p class="subtitle">10. Other terms</p>
			<p>10.1 If any clause in this agreement is completely or partially invalid or unenforceable for whatever reason, or violates any applicable law, the clause shall be deemed deleted, but the remaining clauses of this agreement shall still be valid and have Binding.</p>
			<p>10.2 Travelmate reserves the right to modify this agreement at any time in accordance with changes in relevant laws and regulations, as well as the adjustment of the company's operating conditions and business strategies. The revised agreement will be accompanied by the new version of the software release. In the event of a dispute, the latest agreement text shall prevail. If you do not agree with the changed content, the user can delete the software by himself. If the user continues to use the software, it is deemed that you accept the changes in this agreement.</p>
			<p>10.3 Travelmate has the right to interpret and modify this agreement to the maximum extent permitted by law.</p>`
  }
}